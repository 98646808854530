import { useCallback, useEffect } from 'react'

import logger from '@lib/logger'
import axios from '@services/MixAxios'
import MixMix from '@services/MixMix'

interface UseMarkShareReadProps {
  shareIDs: (number | undefined)[] | undefined
}

export const useMarkShareRead = ({ shareIDs }: UseMarkShareReadProps) => {
  const markShareAsRead = useCallback((shareID: number | undefined) => {
    if (!shareID) return
    axios.request(MixMix.share.markRead(shareID)).catch(err => {
      logger.warn('Failed to mark share as read', err)
    })
  }, [])

  useEffect(() => {
    if (!shareIDs) return
    shareIDs.forEach(markShareAsRead)
  }, [shareIDs, markShareAsRead])
}
