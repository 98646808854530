import React, { useEffect, useMemo, useState } from 'react'

import { ProgressiveImageWithManagedState } from '@common/ProgressiveImage'
import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useGlobalContext } from '@context/GlobalContexts'
import { useTrackPageView } from '@hooks/analytics/useTrackPageView'
import { useIframeBlur } from '@hooks/useIframeBlur'
import { useMarkShareRead } from '@hooks/useMarkShareRead'
import { useMobileLayout } from '@hooks/useMobileLayout'
import usePerceivedMediaTypeEffect from '@hooks/usePerceivedMediaType'
import { useVideoPlayer } from '@hooks/useVideoPlayer'
import { resolveMedia } from '@lib/mediaResolver'
import { IntoUrl } from '@models/IntoUrl'
import { useTrackContentDwell } from '@tracking/events/useTrackContentDwell'
import { useTrackContentView } from '@tracking/events/useTrackContentView'
import { useCurrentUrlContext } from '../CurrentUrlContext'
import { UrlArticleCard } from './UrlArticleCard'
import UrlVideo from './UrlVideo'
import { useVideoPlayerContext } from './VideoPlayerContext'

export const CHROME_TABLET_THRESHOLD = 0.95
export const DEFAULT_THRESHOLD = 1

export const STRONG_GRADIENT_PROVIDERS = ['youtube', 'soundcloud']
const SECOND_TO_MS = 1000

interface UrlMediaProps {
  url: IntoUrl
  isActive: boolean
}

const UrlMediaComponent: React.FC<UrlMediaProps> = ({ url, isActive }) => {
  const { currentUserID: userId } = useCurrentUserContext()
  const [isPlayerReady, setIsPlayerReady] = useState(false)
  const { duration } = useVideoPlayerContext()
  const {
    browserInfo: { browser },
  } = useGlobalContext()
  const { perceivedMediaType, mediaComponentType } = useCurrentUrlContext()
  const shouldRenderMobile = useMobileLayout()

  // Content-related state variables.
  // Video-related state variables
  const [maxProgressPercentage, setMaxProgressPercentage] = useState(0)
  const { isVideo, provider, resolvedMediaType } = resolveMedia(url)

  const videoPlayer = useVideoPlayer(provider)
  const { playerRef } = videoPlayer

  usePerceivedMediaTypeEffect({
    isWebsite: url.meta.isWebsite,
    resolvedMediaType,
    playerRef,
    isPlayerReady,
    provider,
  })

  const shareIDs = useMemo(() => url.shares?.map(({ shareID }) => shareID), [url.shares])
  useMarkShareRead({ shareIDs })
  useTrackContentView({ userId, url, isActive })

  useTrackPageView(
    {
      current_resource_id: url.url_id,
      current_resource_type: url.type,
      is_liked: url.meta.isInto,
      is_disliked: url.meta.isDisliked,
      is_saved: url.meta.isSaved,
    },
    { skip: !isActive }
  )

  useIframeBlur()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [url.url_id])

  // Tracking content dwell
  useTrackContentDwell({
    userId,
    url,
    perceivedMediaType,
    maxProgressPercentage,
    contentTimeTotalMsec: duration * SECOND_TO_MS,
    isActive,
  })

  if (
    mediaComponentType === 'unknown' && // Skip rendering for unknown media types to avoid glitchy UI.
    !browser.bot && // Allow bots to render unknown media types to let them see the image.
    !shouldRenderMobile
  ) {
    return null
  }

  if (mediaComponentType === 'ArticleCard' && !shouldRenderMobile) {
    return <UrlArticleCard url={url} />
  }

  return (
    <div className="relative size-full">
      <div className="pointer-events-none flex size-full items-center">
        <ProgressiveImageWithManagedState
          src={url?.thumbnail ?? ''}
          blurPlaceholder={url.tiny_thumbnail ?? ''}
          alt={url.title}
          contentSize={url.meta.contentSize}
          className={`w-full ${isPlayerReady ? 'z-10' : 'z-20'}`}
        />
      </div>
      {isVideo && isActive ? (
        <UrlVideo
          url={url}
          videoPlayer={videoPlayer}
          isPlayerReady={isPlayerReady}
          setIsPlayerReady={setIsPlayerReady}
          setMaxProgressPercentage={setMaxProgressPercentage}
        />
      ) : null}
    </div>
  )
}

UrlMediaComponent.displayName = 'UrlMedia'

export const UrlMedia = UrlMediaComponent
