import { MutableRefObject, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'

import { globals } from '@lib/messages/protobuf'
import { useCurrentUrlContext } from '@pages/url/CurrentUrlContext'
import { useAudioPresence } from './useAudioPresence'

const usePerceivedMediaTypeEffect = ({
  resolvedMediaType,
  isWebsite,
  playerRef,
  isPlayerReady,
  provider,
}: {
  resolvedMediaType: globals.MediaType | undefined
  isWebsite: boolean
  playerRef: MutableRefObject<ReactPlayer | null>
  isPlayerReady: boolean
  provider: string
}) => {
  const { setPerceivedMediaType, setMediaComponentType } = useCurrentUrlContext()
  const [isAudioPresent, setIsAudioPresent] = useState<boolean | null>(null)
  useAudioPresence(playerRef, isPlayerReady, provider, setIsAudioPresent)

  useEffect(() => {
    const { AUDIO, VIDEO, GIF, IMAGE } = globals.MediaType
    const isPlayable = resolvedMediaType && [VIDEO, AUDIO, GIF].includes(resolvedMediaType)
    const isAudio = resolvedMediaType === AUDIO
    const isVideo = resolvedMediaType === VIDEO
    const isGif = resolvedMediaType === GIF

    if (isPlayable) {
      setMediaComponentType('UrlMedia')
      if (isAudioPresent && isAudio) {
        setPerceivedMediaType(AUDIO)
        return
      }
      if (isAudioPresent && isVideo) {
        setPerceivedMediaType(VIDEO)
        return
      }
      if (isGif || (isAudioPresent === false && isVideo)) {
        setPerceivedMediaType(GIF)
        return
      }
      return
    }

    if (!isWebsite) {
      setPerceivedMediaType(IMAGE)
      setMediaComponentType('UrlMedia')
      return
    }

    setMediaComponentType('ArticleCard')
  }, [isAudioPresent, isWebsite, resolvedMediaType, setPerceivedMediaType, setMediaComponentType])
}

export default usePerceivedMediaTypeEffect
