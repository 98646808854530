import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion'
import Image from 'next/image'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { ProgressiveImageWithManagedState } from '@common/ProgressiveImage'
import { useTrackClick } from '@hooks/analytics/useTrackClick'
import { useMobileLayout } from '@hooks/useMobileLayout'
import { IntoUrl } from '@models/IntoUrl'
import urlApi from '@redux/api/urlApi'
import { IntoUrlSummaryDetail } from '@services/IntoAPI'
import { UrlMetaDebugInfo } from './UrlMetaDebugInfo'

const MOBILE_FOOTER_DELAY_OFFSET = 2
const MIN_MS_TO_SHOW_LOADING = 500
const NARROW_WIDTH_THRESHOLD = 800
const MAX_PERCENT_OF_SCREEN_HEIGHT = 0.8
const MotionWrapper = React.forwardRef<
  HTMLDivElement,
  HTMLMotionProps<'div'> & {
    className?: string
    delay?: number
  }
>(({ children, className = '', delay, ...props }, ref) => (
  <motion.div
    ref={ref}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 20 }}
    transition={{ duration: 0.3, delay }}
    className={`${className}`}
    {...props}
  >
    {children}
  </motion.div>
))

MotionWrapper.displayName = 'MotionWrapper'

export const UrlArticleCard = ({ url }: { url: IntoUrl }) => {
  const shouldRenderMobile = useMobileLayout()
  const { data, isLoading, error } = urlApi.useGetSummaryQuery({ urlId: url.url_id }, { skip: shouldRenderMobile })
  const [isShowingLoading, setIsShowingLoading] = useState(true)
  const [contentHeight, setContentHeight] = useState(0)
  const contentRef = useRef<HTMLDivElement>(null)
  const trackClick = useTrackClick()
  const [isNarrow, setIsNarrow] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const checkWidth = () => {
      if (containerRef.current) {
        setIsNarrow(containerRef.current.offsetWidth <= NARROW_WIDTH_THRESHOLD)
      }
    }

    checkWidth()
    window.addEventListener('resize', checkWidth)
    return () => window.removeEventListener('resize', checkWidth)
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShowingLoading(false)
    }, MIN_MS_TO_SHOW_LOADING)

    return () => clearTimeout(timer)
  }, [])

  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight)
    }
  }, [data, isLoading, isShowingLoading])

  const handleCardClick = useCallback(() => {
    if (shouldRenderMobile) return
    trackClick('urlOpenExternal')
    window.open(url.url, '_blank', 'noopener,noreferrer')
  }, [trackClick, url.url, shouldRenderMobile])

  // Truncate the description before any URLs since they:
  // - are long and don't typically contain many breaking characters, which
  //   breaks our UI
  // - aren't very useful to include in the description
  const truncateBeforeUrl = useCallback((text: string) => {
    // This regex matches common URL patterns.
    const urlRegex = /https?:\/\/[^\s]+|www\.[^\s]+/i
    const match = text.match(urlRegex)
    return match ? text.slice(0, match.index).trim() : text
  }, [])

  const truncatedDescription = useMemo(
    () => (url.description ? truncateBeforeUrl(url.description) : ''),
    [url.description, truncateBeforeUrl]
  )

  const hasSummary = data?.summary && !data.summary.blocked && !error
  const quickSummary = hasSummary ? data.summary.quickSummary : null

  const renderMetaContent = () => {
    return (
      <motion.div animate={{ height: contentHeight }} transition={{ duration: 0.3 }} style={{ minHeight: '120px' }}>
        <div ref={contentRef}>
          <AnimatePresence mode="wait">
            {isLoading || isShowingLoading ? (
              <LoadingSkeleton key="loading" />
            ) : (
              <MetaContent
                key="content"
                url={url}
                hasSummary={!!hasSummary}
                quickSummary={quickSummary}
                details={hasSummary ? data.summary.details : []}
                description={truncatedDescription}
                onHeightChange={setContentHeight}
              />
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    )
  }

  if (shouldRenderMobile) {
    return (
      <motion.div className="relative flex size-full items-center justify-center">
        <BackgroundImage url={url} />
        <div className="z-10 w-full">
          <div className="flex cursor-pointer items-start" onClick={handleCardClick}>
            <div className="w-full">
              <ThumbnailImage url={url} isNarrow={true} />
            </div>
          </div>
        </div>
      </motion.div>
    )
  }

  return (
    <motion.div className="relative flex size-full items-center justify-center">
      <BackgroundImage url={url} />
      <div ref={containerRef} className="z-10 w-full max-w-5xl px-2 sm:px-4 md:px-8 lg:px-12">
        <AnimatePresence mode="wait">
          <motion.div
            key={isNarrow ? 'narrow' : 'wide'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className={`flex cursor-pointer items-start rounded-2xl p-2 ${
              isNarrow ? 'flex-col space-y-3' : 'md:flex-row md:space-x-10 md:space-y-0'
            }`}
            onClick={handleCardClick}
          >
            <div className={`w-full ${isNarrow ? '' : 'md:w-[55%]'}`}>
              <ThumbnailImage url={url} isNarrow={isNarrow} />
            </div>
            <motion.div className={`w-full ${isNarrow ? '' : 'md:w-[45%]'}`} layout>
              <motion.div className="flex size-full flex-col justify-between" layout>
                <div>
                  <div
                    className={`mb-3 text-xl font-bold leading-tight ${
                      isNarrow ? 'line-clamp-4' : 'line-clamp-5 md:line-clamp-3'
                    }`}
                  >
                    {url.title}
                  </div>
                  {renderMetaContent()}
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      </div>
    </motion.div>
  )
}

const BackgroundImage = ({ url }: { url: IntoUrl }) => (
  <div className="absolute inset-0 hidden overflow-hidden sm:block">
    {url.tiny_thumbnail && (
      <Image
        priority
        src={url.tiny_thumbnail}
        alt={url.title}
        className="scale-110 object-cover blur-2xl"
        layout="fill"
      />
    )}
    <div className="absolute inset-0 bg-primary/85 light:bg-gray-100/60" />
  </div>
)

const ThumbnailImage = ({ url, isNarrow }: { url: IntoUrl; isNarrow: boolean }) => {
  const [maxHeight, setMaxHeight] = useState('80vh')
  const shouldRenderMobile = useMobileLayout()

  useEffect(() => {
    const updateMaxHeight = () => {
      setMaxHeight(`${window.innerHeight * MAX_PERCENT_OF_SCREEN_HEIGHT}px`)
    }

    updateMaxHeight()
    window.addEventListener('resize', updateMaxHeight)
    return () => window.removeEventListener('resize', updateMaxHeight)
  }, [])

  return (
    <div
      className={`mb-3 w-full overflow-hidden ${
        shouldRenderMobile
          ? ''
          : `rounded-lg ${isNarrow ? 'md:mt-4 md:rounded-lg' : 'md:max-w-[600px] md:rounded-l-lg md:rounded-r-md'}`
      }`}
      style={{
        maxHeight,
        marginTop: shouldRenderMobile ? '0' : isNarrow ? '1rem' : '0',
        height: shouldRenderMobile ? 'auto' : isNarrow ? '16rem' : 'auto',
      }}
    >
      <ProgressiveImageWithManagedState
        src={url?.thumbnail ?? ''}
        blurPlaceholder={url.tiny_thumbnail ?? ''}
        alt={url.title}
        contentSize={url.meta.contentSize}
      />
    </div>
  )
}

const LoadingSkeleton = () => {
  const [widths, setWidths] = useState<string[]>([])

  useEffect(() => {
    const generateWidths = () => {
      return Array.from({ length: 4 }).map(() => {
        // eslint-disable-next-line no-magic-numbers
        return `${Math.random() * (100 - 80) + 80}%`
      })
    }
    setWidths(generateWidths())
  }, [])

  return (
    <motion.div
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col space-y-3"
      style={{ height: '120px' }}
    >
      <div className="mt-2 space-y-3">
        {widths.map((width, index) => (
          <div
            key={index}
            className="h-2 animate-shimmer rounded bg-gradient-to-r from-contrast/10 via-contrast/20 to-contrast/10 bg-[length:200%_100%]"
            style={{ width }}
          />
        ))}
      </div>
    </motion.div>
  )
}

const MetaContent = ({
  hasSummary,
  quickSummary,
  details,
  description,
  url,
  onHeightChange,
}: {
  hasSummary: boolean
  quickSummary: string | null
  details: IntoUrlSummaryDetail[]
  description: string
  url: IntoUrl
  onHeightChange: (height: number) => void
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const shouldRenderMobile = useMobileLayout()

  useEffect(() => {
    if (containerRef.current) {
      onHeightChange(containerRef.current.offsetHeight)
    }
  }, [details, description, onHeightChange])

  const content = hasSummary ? details : [{ label: 'Description', info: description }]
  const animationDelay = 0.1

  return (
    <MotionWrapper ref={containerRef} className={`space-y-4 ${hasSummary ? 'mt-10' : ''}`}>
      {hasSummary && <MotionWrapper className="-mt-9 mb-9 font-normal">{quickSummary}</MotionWrapper>}
      {content.map((item, index) => (
        <MotionWrapper
          key={item.label || `description-${index}`}
          delay={(index + 1) * animationDelay}
          className="flex flex-col -space-y-1 overflow-hidden text-base"
        >
          {hasSummary && (
            <div className="flex items-center">
              <div className="font-semibold">{item.label?.replace(/:$/, '')}</div>
            </div>
          )}
          <div
            className={
              hasSummary ? 'opacity-75' : 'break-words font-light opacity-70 sm:line-clamp-[8] lg:line-clamp-[10]'
            }
          >
            {item.info}
          </div>
        </MotionWrapper>
      ))}
      <MotionWrapper delay={(content?.length + (shouldRenderMobile ? MOBILE_FOOTER_DELAY_OFFSET : 1)) * animationDelay}>
        <Footer url={url} />
      </MotionWrapper>
    </MotionWrapper>
  )
}

const Footer = ({ url }: { url: IntoUrl }) => (
  <div className="mt-10 flex items-center justify-between gap-2">
    <div className="flex items-center gap-2 overflow-hidden">
      <span className="truncate text-sm font-medium opacity-30">
        {url.meta?.provider?.openButtonTitleLong ?? url.hostname}
      </span>
      <UrlMetaDebugInfo
        url={url}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
        }}
      />
    </div>
  </div>
)

export default UrlArticleCard
