import { useEffect } from 'react'

export const useIframeBlur = () => {
  const handleWindowBlur = () => {
    setTimeout(() => {
      if (document.activeElement?.tagName === 'IFRAME') {
        window.focus()
      }
    })
  }

  useEffect(() => {
    window.addEventListener('blur', handleWindowBlur)
    return () => {
      window.removeEventListener('blur', handleWindowBlur)
    }
  }, [])
}
