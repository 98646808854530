import Long from 'long'
import { useCallback } from 'react'

import useDebouncedEffect from '@hooks/useDebouncedEffect'
import logger from '@lib/logger'
import { commands, globals } from '@lib/messages/protobuf'
import { IntoUrl } from '@models/IntoUrl'
import IntoAPI from '@services/IntoAPI'
import axios from '@services/MixAxios'
import MixMix from '@services/MixMix'
import { broadcastTrackingMessage } from '../broadcast/trackingMessage'

import TrackContentView = commands.TrackContentView
import ContentViewType = globals.ContentViewType

interface UseTrackContentViewProps {
  userId: number | undefined
  url: IntoUrl
  isActive: boolean
}

export const useTrackContentView = ({ userId, url, isActive }: UseTrackContentViewProps) => {
  const urlId = url.url_id

  const trackContentView = useCallback(() => {
    const createMessage = (context: commands.CommandContext) => {
      return new TrackContentView({
        userId: userId ? Long.fromNumber(userId) : null,
        urlId: Long.fromString(urlId),
        viewType: ContentViewType.CONTENT_VIEW,
        context,
      })
    }

    const initialConfig = MixMix.commands.trackContentView({})
    void broadcastTrackingMessage('TrackContentView', initialConfig, createMessage).catch(err =>
      logger.warn('Failed to track content view', err)
    )
  }, [urlId, userId])

  const intoUrlSeen = useCallback(() => {
    if (userId) {
      axios
        .request(IntoAPI.url.markSeen({ url_id: urlId }))
        .catch(err => logger.warn('Failed to mark url as seen', err))
    }
  }, [urlId, userId])

  useDebouncedEffect(() => {
    if (!urlId || !isActive) return
    trackContentView()
    intoUrlSeen()
  }, [intoUrlSeen, trackContentView, urlId, userId, isActive])
}
