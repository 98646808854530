import Long from 'long'
import { useCallback, useRef } from 'react'

import useDebouncedEffect from '@hooks/useDebouncedEffect'
import { commands, globals } from '@lib/messages/protobuf'
import { IntoUrl } from '@models/IntoUrl'
import MixMix from '@services/MixMix'
import { broadcastTrackingMessage } from '../broadcast/trackingMessage'

import CommandContext = commands.CommandContext

const { TrackContentDwell } = commands
interface ITrackContentDwellParams {
  userId: number | undefined
  url: IntoUrl
  maxProgressPercentage: number
  contentTimeTotalMsec: number
  perceivedMediaType: globals.MediaType
  isActive: boolean
}

export const useTrackContentDwell = ({
  userId,
  url,
  maxProgressPercentage,
  contentTimeTotalMsec,
  perceivedMediaType,
  isActive,
}: ITrackContentDwellParams) => {
  const dwellStartRef = useRef<number | null>(null)
  const { url_id: urlId } = url

  const handleDwellEvent = useCallback(async () => {
    if (!urlId || !isActive) return

    if (!dwellStartRef.current) return

    const timeSpentMsec = Date.now() - dwellStartRef.current

    const createMessage = (context: CommandContext) => {
      const hasDuration = Number.isFinite(contentTimeTotalMsec)
      return new TrackContentDwell({
        urlId: Long.fromString(urlId),
        userId: userId ? Long.fromNumber(userId) : null,
        timeSpentMsec: Long.fromNumber(timeSpentMsec),
        contentTimeTotalMsec: hasDuration ? Long.fromNumber(Math.trunc(contentTimeTotalMsec)) : null,
        maxProgressPercentage: Math.trunc(maxProgressPercentage),
        perceivedMediaType,
        context,
      })
    }

    const initialTrackingConfig = MixMix.commands.trackContentDwell({})

    await broadcastTrackingMessage('TrackContentDwell', initialTrackingConfig, createMessage)

    dwellStartRef.current = null
  }, [userId, urlId, contentTimeTotalMsec, maxProgressPercentage, perceivedMediaType, isActive])

  const handleVisibilityChange = useCallback(
    (event: Event) => {
      if (
        !isActive ||
        event.type === 'blur' ||
        event.type === 'modalOpen' ||
        (event.type === 'visibilitychange' && document.hidden && !document.hasFocus())
      ) {
        void handleDwellEvent().catch(() => {})
      }

      if (
        isActive &&
        (event.type === 'focus' ||
          event.type === 'modalClose' ||
          (event.type === 'visibilitychange' && !document.hidden))
      ) {
        dwellStartRef.current = Date.now()
      }
    },
    [handleDwellEvent, isActive]
  )

  useDebouncedEffect(() => {
    const windowEvents = ['focus', 'blur', 'visibilitychange']
    const documentEvents = ['modalOpen', 'modalClose']
    windowEvents.forEach(event => window.addEventListener(event, handleVisibilityChange))
    documentEvents.forEach(event => document.addEventListener(event, handleVisibilityChange))
    window.addEventListener('beforeunload', handleDwellEvent)

    dwellStartRef.current = dwellStartRef.current ?? Date.now()
    return () => {
      windowEvents.forEach(event => window.removeEventListener(event, handleVisibilityChange))
      documentEvents.forEach(event => document.removeEventListener(event, handleVisibilityChange))
      window.removeEventListener('beforeunload', handleDwellEvent)
    }
  }, [handleDwellEvent, handleVisibilityChange])

  const handleDwellEventRef = useRef(handleDwellEvent)

  useDebouncedEffect(() => {
    handleDwellEventRef.current = handleDwellEvent
  }, [handleDwellEvent])

  useDebouncedEffect(() => {
    return () => {
      if (!urlId || !isActive) return
      void handleDwellEventRef.current().catch(() => {})
    }
  }, [urlId, isActive])
}
